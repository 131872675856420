import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404 Page"
      keywords={[
        `smarta kontrakt sverige svenskt företag`,
        ` svenskt företag blockchain utveckling sverige`,
        `Blockchain development smarta kontrakt och blockchain utveckling`,
        `Smart Contract development `,
        `Ethereum Development sverige eterumutveckling `,
        `Solidity Development svenskt företag `,
        `Hyperledger Development Sweden`,
        `ICO STO Development Sweden Stockholm Malmo`,
        `Smart contract Audit Sweden smart kontraktskontroll`,
        `Blockchain Consulting and Development Sweden Stockholm Malmo`,
        `Full-Stack Development Sweden Stockholm Malmo`,
        `svensk blockchain utveckling sverige`,
        `svensk blockchain smart kontraktsutveckling`,
        `Full-Stack Development services sverige`,
        `blockchain company Sweden`,
        `ERC20 Ethereum Token Development Sweden`,
        `Swedish Ethereum Development ICO`,
        `gatsby smarta kontrakt och blockchain utveckling sverige`,
        `react sverige svenskt företag `,
        `Nodejs sverige svenskt företag `,
        `JAMStack Swedish Sweden Stockholm Malmo`,
        `Gatsby Website development Sweden`,
        `React Redux Graphql Front developer svenskt företag `,
        `React Redux Graphql Backend development Sweden Stockholm Malmo`,
        `Cryptocurency development Sweden`,
        `Wallet development Cryptocurency Smart Contract`,
        `Website development Sweden`,
        `Website blog hemsida utveckling Sweden Stockholm Malmo`,
        `Website blog hemsida utveckling svenskt företag`
      ]}
    />
    <NotFoundWrapper>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </NotFoundWrapper>
  </Layout>
);
const NotFoundWrapper = styled.div`
  margin: 12rem;
`;

export default NotFoundPage;
